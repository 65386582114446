import styled, { css } from 'styled-components'

// TODO: this should be moved to a shared location and imported from there, so that it can be used in multiple places
type Theme = { colors: darkThemeColors | lightThemeColors }

export const Content = styled.div<{ $hasScroll?: boolean | undefined, $minHeight?: number | string | undefined }>`${(
  { $hasScroll, $minHeight },
) =>
  css`
  position: relative;
  min-height: ${typeof $minHeight === 'string' ? $minHeight : `${$minHeight ?? 250}px`};
  width: 100%;
  max-height: calc(90vh - 60px);
  overflow: ${$hasScroll ? 'auto' : 'hidden'};
  line-height: 1.5;
` as any}`

export const I = styled.i<{
  theme?: Theme | undefined
  $left?: boolean | undefined
  $size?: string | undefined
  $clickable?: boolean | undefined
}>`${({ theme, $left, $size, $clickable }) =>
  css`
  position: absolute;
  font-size: ${$size ?? '40px'};
  color: ${theme.colors.swatch9};
  ${$left ? 'left: 10px' : 'right: 10px'};
  top: 10px;
  ${$clickable && 'cursor: pointer;'}
`}`

type DialogStyleProps = {
  theme: Theme
  $small?: boolean | undefined
  $medium?: boolean | undefined
  $fullWidth?: boolean | undefined
}

export const Dialog = styled.div<DialogStyleProps>`${(
  { theme, $small, $medium, $fullWidth },
) =>
  css`
  position: fixed;
  margin-top: calc(50vh - 20px);
  margin-left: 50vw;
  transform: translate(-50%, -50%);
  width: ${$small ? 450 : ($medium ? 675 : ($fullWidth ? '90%' : 900))}${!$fullWidth ? 'px' : ''};
  background: ${theme.colors.swatch5 ?? '#2A3038'};
  border-radius: 10px;
  z-index: 200;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
`}`

export const DialogBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  filter: blur(5px);
  transform: scale(1.1);
  z-index: 150;
`

export const Header = styled.div`${({ theme }) =>
  css`
  position: relative;
  top: 0;
  width: 100%;
  min-height: 60px;
  border-bottom: solid 1px ${theme['colors'].swatch6 ?? '#373f49'};
  display: flex;
  align-items: center;
  justify-content: center;
`}`

export const HeaderTitle = styled.div<{
  theme: Theme
  $headerWidth?: string | undefined
  $bigger?: boolean | undefined
}>`${(
  { theme, $headerWidth, $bigger },
) =>
  css`
  text-align: center;
  display: inline-block;
  font-size: ${$bigger ? '35px' : '25px'};
  font-weight: 500;
  user-select: none;
  color: ${theme.colors.swatch9};
  ${$headerWidth ? `width: ${$headerWidth}` : ''}
`}`

export const FixedButton = styled.div`
  position: fixed;
  width: calc(100% - 56px);
  bottom: 32px;
`
